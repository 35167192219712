<template>
  <div id="suscripciones">
    <div>
      <v-toolbar flat color="white">
        <v-spacer />
        <v-dialog v-model="mostrarDialog" max-width="700px" persistent scrollable>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text style="margin-top: -10px">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <transition name="bounce">
                      <v-alert
                        v-if="editedItem.precio !== editedItem.precio_inicial && editedItem.id > 0"
                        color="orange accent-2"
                        icon="mdi-bell-alert"
                        :value="true"
                      >
                        {{ $t('suscripciones.noty_to_all') }}
                      </v-alert>
                    </transition>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="editedItem.nombre"
                      :rules="[rules.required]"
                      :label="$t('common.name') + ' *'"
                      :outlined="outlined"
                    />
                  </v-flex>
                  <v-flex v-if="paymentModule" :xs6="editedItem.taxPercent > 0" :xs4="editedItem.taxPercent === 0">
                    <v-select
                      v-model="editedItem.taxType"
                      :items="taxTypes"
                      style="width: 99%"
                      :label="$t('common.taxType')"
                      auto
                      :outlined="outlined"
                      required
                      item-text="name"
                      item-value="id"
                    />
                  </v-flex>
                  <v-flex v-if="paymentModule" :xs6="editedItem.taxPercent > 0" :xs4="editedItem.taxPercent === 0">
                    <v-text-field
                      v-model="editedItem.taxPercent"
                      :label="$t('common.taxPercent')"
                      type="number"
                      step="0.01"
                      min="0.01"
                      :outlined="outlined"
                      @input="cambiaPrecio('writing')"
                    />
                  </v-flex>
                  <v-flex v-if="paymentModule" :xs6="editedItem.taxPercent > 0" :xs4="editedItem.taxPercent === 0">
                    <v-text-field
                      v-model="editedItem.precio"
                      :rules="[rules.min_or_equal]"
                      :label="$t('common.precio') + ' *'"
                      :outlined="outlined"
                      @keyup="cambiaPrecio('writing')"
                      @blur="cambiaPrecio('blur')"
                    />
                  </v-flex>
                  <v-flex v-if="paymentModule && editedItem.precio > 0 && editedItem.taxPercent > 0" xs6>
                    <v-text-field
                      v-model="editedItem.importe"
                      :label="$t('facturacion.importe')"
                      readonly="readonly"
                      :outlined="outlined"
                    />
                  </v-flex>
                  <v-flex v-if="habilitado_idioma" xs12>
                    <v-radio-group
                      v-model="editedItem.idioma"
                      row
                      style="margin-top: -10px; margin-bottom: -30px; float: right"
                      :outlined="outlined"
                    >
                      <v-radio color="indigo darken-3" :label="$t('common.spanish')" value="es" />
                      <v-radio color="indigo darken-3" :label="$t('common.english')" value="en" />
                    </v-radio-group>
                  </v-flex>
                  <v-flex v-if="habilitado_idioma" xs12>
                    <v-textarea
                      v-if="editedItem.idioma === 'es'"
                      v-model="editedItem.descripcion_es"
                      :outlined="outlined"
                      rows="2"
                      :label="$t('common.descripcion')"
                      placeholder="Escriba aqui el texto en Español"
                    />
                    <v-textarea
                      v-else
                      v-model="editedItem.descripcion_en"
                      :outlined="outlined"
                      rows="2"
                      :label="$t('common.descripcion')"
                      placeholder="Write here the text in English"
                    />
                  </v-flex>
                  <v-flex v-else xs12>
                    <v-textarea
                      v-model="editedItem.descripcion_es"
                      rows="2"
                      :label="$t('common.descripcion')"
                      :outlined="outlined"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-alert
                      v-if="editedItem.destinatario === 0 && !es_medyola"
                      type="info"
                      outlined
                      :value="true"
                      style="padding: 5px;"
                    >
                      {{ $t('suscripciones.explicacion_destinatario', { name: nombreEntorno }) }}
                    </v-alert>
                  </v-flex>
                  <v-flex xs12 sm8>
                    <v-autocomplete
                      v-model="editedItem.destinatario"
                      :items="pacientes"
                      chips
                      :outlined="outlined"
                      item-text="label"
                      item-value="value"
                      style="width: 98%"
                      :label="$t('suscripciones.destinatario') + ' *'"
                    >
                      <template slot="selection" slot-scope="data">
                        <v-chip
                          color="primary lighten-4"
                          :selected="data.selected"
                          close
                          class="chip--select-multi"
                          @click:close="quitarDestinatario(data.item)"
                        >
                          {{ data.item.label }}
                        </v-chip>
                      </template>
                      <template slot="item" slot-scope="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item" />
                        </template>
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title>{{ data.item.label }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-switch
                      v-model="editedItem.tarifaPlana"
                      value="Yes"
                      :label="
                        editedItem.tarifaPlana === 'Yes'
                          ? $t('suscripciones.tarifa_plana')
                          : $t('suscripciones.a_medida')
                      "
                      color="primary"
                    />
                  </v-flex>
                  <v-flex v-if="editedItem.tarifaPlana !== 'Yes' && editedItem.servicios.length > 0" xs12>
                    <h4>
                      <b>{{ $t('suscripciones.servicios_incluidos') }}</b>
                    </h4>
                    <ul style="max-height: 300px; overflow-y: scroll;">
                      <li v-for="(item, index) in editedItem.servicios" :key="index">
                        <v-icon small @click="eliminarListado(index)">mdi-delete</v-icon>
                        &nbsp;&nbsp;&nbsp;{{ item.nombre }}&nbsp;
                        <span v-if="item.nsesiones === 100">({{ item.sesiones }})</span>
                        <span v-else>({{ item.sesiones }} {{ $tc('suscripciones.sesiones', item.nsesiones) }})</span>
                      </li>
                    </ul>
                  </v-flex>
                  <v-flex v-if="editedItem.tarifaPlana !== 'Yes'" xs7>
                    <v-select
                      v-model="editedItem.medida.servicio"
                      :items="servicios"
                      style="width: 99%"
                      :label="$t('suscripciones.servicio') + ' *'"
                      auto
                      required
                      item-text="name"
                      item-value="id"
                      :outlined="outlined"
                    />
                  </v-flex>
                  <v-flex v-if="editedItem.tarifaPlana !== 'Yes'" xs4>
                    <v-select
                      v-model="editedItem.medida.sesiones"
                      :outlined="outlined"
                      :items="numsesiones"
                      style="width: 99%"
                      :label="$t('packs.num_sesiones') + ' *'"
                      auto
                      required
                      item-text="name"
                      item-value="id"
                    />
                  </v-flex>
                  <v-flex v-if="editedItem.tarifaPlana !== 'Yes'" xs1 class="text-center">
                    <v-btn color="primary" fab small dark style="margin-left: 0px;" @click="addItemSeccion()">
                      <v-icon>{{ !editedItem.medida.editando ? 'mdi-plus' : 'mdi-pencil' }}</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn :color="custom_template ? 'primary darken-1' : 'blue darken-1'" text @click.native="close">{{
                $t('common.cancel')
              }}</v-btn>
              <v-btn :color="custom_template ? 'primary darken-1' : 'blue darken-1'" text @click.native="save">{{
                $t('common.save')
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="suscripciones"
        class="tabla_suscripciones"
        :footer-props="{
          itemsPerPageText: $t('datatable.rows_per_page'),
          itemsPerPageAllText: $t('datatable.all'),
          pageText: '',
        }"
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-center">
              <v-tooltip v-if="props.item.estado === 1" right>
                <template v-slot:activator="{ on }">
                  <v-icon color="green" v-on="on">mdi-record</v-icon>
                </template>
                <span>{{ $t('common.activado') }}</span>
              </v-tooltip>
              <v-tooltip v-else right>
                <template v-slot:activator="{ on }">
                  <v-icon color="grey" v-on="on">mdi-record</v-icon>
                </template>
                <span>{{ $t('common.desactivado') }}</span>
              </v-tooltip>
            </td>
            <td>{{ props.item.nombre }} [{{ paymentModule ? props.item.precio + ' ' + moneda : '' }}]</td>
            <td class="hidden-xs-only">
              {{ props.item.detalles }}
            </td>
            <td class="justify-center layout px-0">
              <v-icon small class="mr-2" @click="editItem(props.item)">mdi-pencil</v-icon>
              <v-icon v-if="props.item.estado === 1" small @click="deleteItem(props.item)">mdi-delete</v-icon>
              <v-icon v-else small @click="activarItem(props.item)">mdi-arrow-up</v-icon>
            </td>
          </tr>
        </template>

        <template slot="no-data"> <v-icon>mdi-star</v-icon> {{ $t('suscripciones.sin_suscripciones') }} </template>
      </v-data-table>
    </div>

    <!-- Modal Eliminar Suscripción -->
    <span slot="widget-content">
      <v-dialog v-model="dialog_delete.dialog" dialog_delete max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('suscripciones.delete') }}</span>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <p>{{ $t('suscripciones.confirm_delete') }}</p>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey darken-1" text @click.native="dialog_delete.dialog = false"
              ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
            >
            <v-btn
              color="red darken-1"
              text
              @click.native="
                dialog_delete.dialog = false;
                borrarSuscripcion();
              "
              ><v-icon>mdi-delete</v-icon> {{ $t('common.confirm') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </span>
  </div>
</template>

<script>
import { nameEnv, suscripcionUrl, getHeader } from '../../../config/config';

export default {
  name: 'Suscripciones',
  props: {
    suscripciones: {
      type: Array,
      default: () => [],
    },
    pacientes: {
      type: Array,
      default: () => [],
    },
    servicios: {
      type: Array,
      default: () => [],
    },
    mostrarDialog: {
      type: Boolean,
      default: false,
    },
    paymentModule: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    taxTypes: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      habilitado_idioma: nameEnv === 'medyola',
      dialog2: false,
      fecha_actual: new Date(),
      fechaInicio: null,
      fechaFin: null,
      time: null,
      headers: [
        { text: this.$t('common.status'), value: 'estado' },
        { text: this.$t('suscripciones.suscripcion'), value: 'nombre' },
        { text: this.$t('common.detalles'), value: 'detalles', class: 'hidden-xs-only' },
        { text: this.$t('common.actions'), value: '', sortable: false, class: 'text-center' },
      ],
      items: this.suscripciones,
      editedIndex: -1,
      editedItem: {
        id: 0,
        nombre: '',
        idioma: 'es',
        descripcion_es: '',
        descripcion_en: '',
        destinatario: '',
        tarifaPlana: 'Yes',
        precio: 0,
        servicios: [],
        medida: {
          servicio: -1,
          sesiones: 0,
          editando: false,
        },
        taxTypes: 0,
        taxPercent: 0,
        importe: 0,
      },
      defaultItem: {
        id: 0,
        nombre: '',
        idioma: 'es',
        descripcion_es: '',
        descripcion_en: '',
        destinatario: '',
        precio: 0,
        tarifaPlana: 'Yes',
        servicios: [],
        medida: {
          servicio: -1,
          sesiones: 0,
          editando: false,
        },
        taxTypes: 0,
        taxPercent: 0,
        importe: 0,
      },
      dialog_delete: {
        name: '',
        dialog: false,
      },
      dataPost: {
        user_id: JSON.parse(window.localStorage.getItem('auth_ehealth'))['id'],
        action: '',
        indice: 0,
        suscripcion: {},
      },
      rules: {},
      numsesiones: [],
      custom_template:
        window.localStorage.getItem('auth_ehealth') &&
        typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== ''
          ? true
          : false,
      es_dingdoc: nameEnv === 'dingdoc',
      es_medyola: nameEnv === 'medyola',
      moneda: nameEnv === 'dingdoc' ? '₡' : '€',
      nombreEntorno: nameEnv,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t('suscripciones.nueva_titulo') : this.$t('suscripciones.editar');
    },
  },
  created() {
    this.rules = {
      required: value => !!value || this.$t('common.obligatorio'),
      min: v => v > 0 || this.$tc('errors.mayor_cero', 0),
      min_or_equal: v => v >= 0 || this.$tc('errors.mayor_cero', 2),
    };
    const nsesions = nameEnv === 'medyola' ? 50 : 10;
    this.numsesiones.push({ id: 0, name: this.$t('suscripciones.ilimitado') });
    for (let i = 1; i <= nsesions; i++) {
      this.numsesiones.push({ id: i, name: i });
    }
  },
  methods: {
    validations() {
      return {
        form: this.rules,
      };
    },

    cambiaPrecio(modo) {
      let precio = parseFloat(this.editedItem.precio);
      if ((isNaN(precio) && modo !== 'writing') || precio < 0 || precio === '') {
        precio = 0;
      }

      if (this.isFreemium && precio <= 0) {
        precio = 1;
      }
      this.editedItem.precio = precio;
      this.editedItem.importe =
        Math.round((parseFloat(precio) + (parseFloat(precio) * this.editedItem.taxPercent) / 100) * 100) / 100;
    },

    editItem(item) {
      this.editedIndex = this.suscripciones.indexOf(item);
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem.id = item.id;
      this.editedItem.nombre = item.nombre;
      this.editedItem.descripcion_es = item.descripcion_es;
      this.editedItem.descripcion_en = item.descripcion_en;
      this.editedItem.precio = this.paymentModule ? item.netAmount : 0;
      this.editedItem.precio_inicial = item.precio;
      this.editedItem.destinatario = item.destinatario;
      this.editedItem.tarifaPlana = item.tarifaPlana;
      this.editedItem.servicios = item.servicios;
      this.editedItem.taxType = item.taxType;
      this.editedItem.taxPercent = item.taxPercent;
      this.editedItem.importe = item.precio;
      const param = { estado: false, tipo: 'suscripciones' };
      this.$emit('cerrarDialog', param);
    },

    deleteItem(item) {
      this.editedItem.id = item.id;
      this.editedIndex = this.suscripciones.indexOf(item);
      this.dialog_delete.dialog = true;
    },

    activarItem(item) {
      this.dataPost.action = 'activar';
      this.editedIndex = this.suscripciones.indexOf(item);
      this.dataPost.suscripcion_id = item.id;
      this.$http.post(suscripcionUrl, this.dataPost, { headers: getHeader() }).then(response => {
        if (response.status === 200 && response.data.estado === '1') {
          this.suscripciones.forEach(function(element) {
            if (element.id === item.id) {
              element.estado = 1;
            }
          });
          this.$emit('mostrarAlert', { tipo: 'success', icono: 'mdi-check', texto: this.$t('success.active_common') });
        } else {
          this.$emit('mostrarAlert', { tipo: 'error', icono: 'mdi-alert-circle', texto: this.$t('errors.try_again') });
        }
      });
    },

    borrarSuscripcion() {
      this.dataPost.action = 'eliminar';
      this.dataPost.suscripcion_id = this.editedItem.id;
      this.$http.post(suscripcionUrl, this.dataPost, { headers: getHeader() }).then(response => {
        if (response.status === 200 && response.data.estado === '1') {
          this.suscripciones.forEach(element => {
            if (element.id === this.editedItem.id) {
              element.estado = 0;
            }
          });
          this.$emit('mostrarAlert', { tipo: 'success', icono: 'mdi-check', texto: this.$t('success.delete_common') });
        } else {
          this.$emit('mostrarAlert', { tipo: 'error', icono: 'mdi-alert-circle', texto: this.$t('errors.try_again') });
        }
      });
      this.close();
    },

    close() {
      const param = { estado: true, tipo: 'suscripciones' };
      this.$emit('cerrarDialog', param);
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.servicios = [];
        this.editedIndex = -1;
      }, 300);
    },
    quitarDestinatario() {
      this.editedItem.destinatario = '';
      // const index = this.editedItem.destinatario.indexOf(item.value);
      // if (index >= 0) this.editedItem.destinatario.splice(index, 1);
    },
    addItemSeccion() {
      if (this.editedItem.medida.servicio > -1) {
        let nombre_servicio = '';
        const servicio_seleccionado = this.editedItem.medida.servicio;
        this.servicios.forEach(function(serv) {
          if (serv.id === servicio_seleccionado) {
            nombre_servicio = serv.name;
          }
        });
        const sesiones =
          this.editedItem.medida.sesiones === 0 ? this.$t('suscripciones.ilimitado') : this.editedItem.medida.sesiones;
        const nsesiones = this.editedItem.medida.sesiones === 0 ? 100 : this.editedItem.medida.sesiones;
        this.editedItem.servicios.push({
          servicio: this.editedItem.medida.servicio,
          sesiones: sesiones,
          nombre: nombre_servicio,
          nsesiones: nsesiones,
        });
        this.editedItem.medida.servicio = -1;
        this.editedItem.medida.sesiones = 0;
      }
    },
    eliminarListado(indice) {
      this.editedItem.servicios.splice(indice, 1);
    },
    save() {
      if (
        !this.validations() ||
        this.editedItem.nombre === '' ||
        (this.paymentModule && this.editedItem.precio === '') ||
        this.editedItem.destinatario === ''
      ) {
        this.$emit('mostrarAlert', {
          tipo: 'warning',
          icono: 'mdi-alert-circle',
          texto: this.$t('errors.form_invalid'),
        });
      } else if (this.editedItem.tarifaPlana !== 'Yes' && this.editedItem.servicios.length === 0) {
        this.$emit('mostrarAlert', {
          tipo: 'warning',
          icono: 'mdi-alert-circle',
          texto: this.$t('suscripciones.error_no_servicios'),
        });
      } else {
        this.editedItem.precio = this.paymentModule ? this.editedItem.precio : 0;
        this.dataPost.action = this.editedIndex > -1 ? 'editar' : 'nuevo';
        this.dataPost.suscripcion = this.editedItem;
        this.dataPost.indice = this.editedIndex;
        this.$http.post(suscripcionUrl, this.dataPost, { headers: getHeader() }).then(response => {
          if (response.status === 200 && response.data.estado === '1') {
            this.$emit('mostrarAlert', { tipo: 'success', icono: 'mdi-check', texto: this.$t('success.save_common') });
            if (this.dataPost.indice > -1) {
              Object.assign(this.suscripciones[this.dataPost.indice], response.data.suscripcion);
              this.suscripciones.push({});
              this.suscripciones.pop();
            } else {
              this.suscripciones.push(response.data.suscripcion);
            }
          } else {
            this.$emit('mostrarAlert', {
              tipo: 'error',
              icono: 'mdi-alert-circle',
              texto: this.$t('errors.try_again'),
            });
          }
          this.snackbar = true;
        });
        this.close();
      }
    },
  },
};
</script>

<style>
.titulo_diag {
  font-family: Roboto;
  font-weight: 300 !important;
  margin-bottom: -10px;
}
.tabla_suscripciones {
  margin-top: -15px !important;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>
