<template>
  <div id="tablavideos">
    <div>
      <v-toolbar flat color="white">
        <v-spacer />
        <v-dialog v-model="mostrarDialog" max-width="770px" persistent scrollable>
          <v-card>
            <v-card-title>
              <span class="headline mb-5">
                {{ formTitle }}
                <span v-if="isReceptionist"> - {{ editedItem.professionalName }}</span>
              </span>
            </v-card-title>
            <v-card-text style="margin-top: -20px">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex v-if="prevTextFeeMessage !== ''" xs12>
                    <v-alert color="warning" icon="mdi-information" :value="true">
                      {{ prevTextFeeMessage }}
                    </v-alert>
                  </v-flex>
                  <v-flex xs12 sm8>
                    <v-text-field
                      v-model="editedItem.nombre"
                      :rules="[rules.required]"
                      :label="$t('common.name') + ' *'"
                      :outlined="outlined"
                    />
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-select
                      v-model="editedItem.estado"
                      :items="estados"
                      style="width: 99%"
                      :label="$t('common.status')"
                      auto
                      required
                      item-text="name"
                      item-value="id"
                      :outlined="outlined"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea
                      v-model="editedItem.descripcion"
                      :label="$t('common.descripcion')"
                      class="textarea_videoconsulta"
                      rows="2"
                      :outlined="outlined"
                    />
                  </v-flex>
                  <v-flex v-if="paymentModule" xs12 lg6 md6>
                    <v-select
                      v-model="editedItem.taxType"
                      :items="taxTypes"
                      style="width: 99%"
                      :label="$t('common.taxType')"
                      auto
                      required
                      item-text="name"
                      item-value="id"
                      :outlined="outlined"
                    />
                  </v-flex>
                  <v-flex v-if="paymentModule" xs12 lg6 md6>
                    <v-text-field
                      v-model="editedItem.taxPercent"
                      :label="$t('common.taxPercent')"
                      type="number"
                      step="0.01"
                      min="0.01"
                      :outlined="outlined"
                      @keyup="cambiaPrecio('writing')"
                      @blur="cambiaPrecio('blur')"
                    />
                  </v-flex>
                  <v-flex
                    v-if="paymentModule"
                    xs12
                    :sm4="!(editedItem.precio > 0 && editedItem.taxPercent > 0)"
                    :sm6="editedItem.precio > 0 && editedItem.taxPercent > 0"
                  >
                    <v-text-field
                      v-model="editedItem.precio"
                      :label="
                        editedItem.precio > 0
                          ? $t('tipovideo.precio')
                          : $t('tipovideo.precio') + ' (' + $t('common.gratuita') + ')'
                      "
                      type="number"
                      step="0.01"
                      min="0"
                      :append-icon="editedItem.precio > 0 ? 'mdi-currency-eur' : ''"
                      :outlined="outlined"
                      @keyup="cambiaPrecio('writing')"
                      @blur="cambiaPrecio('blur')"
                    />
                  </v-flex>
                  <v-flex v-if="paymentModule && editedItem.precio > 0 && editedItem.taxPercent > 0" xs12 sm6>
                    <v-text-field
                      v-model="editedItem.importe"
                      :label="$t('facturacion.importe')"
                      readonly="readonly"
                      :outlined="outlined"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    :sm4="!(editedItem.precio > 0 && editedItem.taxPercent > 0)"
                    :sm6="editedItem.precio > 0 && editedItem.taxPercent > 0"
                  >
                    <v-text-field
                      v-model="editedItem.duracion"
                      :label="$t('tipovideo.duracion')"
                      type="number"
                      min="1"
                      max="1440"
                      suffix="min."
                      append-icon="mdi-clock-outline"
                      :outlined="outlined"
                      @input="cambiaDuracion"
                      @change="modifyDuration"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    :sm4="!(editedItem.precio > 0 && editedItem.taxPercent > 0)"
                    :sm6="editedItem.precio > 0 && editedItem.taxPercent > 0"
                  >
                    <v-select
                      v-model="editedItem.presencial"
                      :items="tipoServicio"
                      style="width: 99%"
                      :label="$t('videoconsulta.tipo')"
                      auto
                      required
                      item-text="name"
                      item-value="id"
                      :outlined="outlined"
                    />
                  </v-flex>
                  <v-flex xs12 style="margin-top: -10px">
                    <v-layout wrap>
                      <v-flex xs3 class="text-sm-center" style="color: #555;">
                        <strong>{{ $t('agenda.disponible') }}:</strong>
                      </v-flex>
                      <v-flex xs2 sm1 class="availableDay">
                        <v-checkbox v-model="editedItem.dias" color="blue" value="0" hide-details />
                        {{ $t('agenda.lunes_redu') }}
                      </v-flex>
                      <v-flex xs2 sm1 class="availableDay">
                        <v-checkbox v-model="editedItem.dias" color="blue" value="1" hide-details />
                        {{ $t('agenda.martes_redu') }}
                      </v-flex>
                      <v-flex xs2 sm1 class="availableDay">
                        <v-checkbox v-model="editedItem.dias" color="blue" value="2" hide-details />
                        {{ $t('agenda.miercoles_redu') }}
                      </v-flex>
                      <v-flex xs3 sm1 class="availableDay">
                        <v-checkbox v-model="editedItem.dias" color="blue" value="3" hide-details />
                        {{ $t('agenda.jueves_redu') }}
                      </v-flex>
                      <v-flex xs3 sm1 class="availableDay">
                        <v-checkbox v-model="editedItem.dias" color="blue" value="4" hide-details />
                        {{ $t('agenda.viernes_redu') }}
                      </v-flex>
                      <v-flex xs3 sm1 class="availableDay">
                        <v-checkbox v-model="editedItem.dias" color="blue" value="5" hide-details />
                        {{ $t('agenda.sabado_redu') }}
                      </v-flex>
                      <v-flex xs2 sm1 class="availableDay">
                        <v-checkbox v-model="editedItem.dias" color="blue" value="6" hide-details />
                        {{ $t('agenda.domingo_redu') }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="primary darken-1" text @click.native="close">{{ $t('common.cancel') }}</v-btn>
              <v-btn color="primary darken-1" text @click.native="save">{{ $t('common.save') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="videoconsultas"
        class="tabla_videos"
        :footer-props="{
          itemsPerPageText: $t('datatable.rows_per_page'),
          itemsPerPageAllText: $t('datatable.all'),
          pageText: '',
        }"
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-left">
              <v-tooltip v-if="props.item.estado === 1" right>
                <template v-slot:activator="{ on }">
                  <v-icon color="green" v-on="on">mdi-record</v-icon>
                </template>
                <span>{{ $t('common.activado') }}</span>
              </v-tooltip>
              <v-tooltip v-else right>
                <template v-slot:activator="{ on }">
                  <v-icon color="grey" v-on="on">mdi-record</v-icon>
                </template>
                <span>{{ $t('common.desactivado') }}</span>
              </v-tooltip>
            </td>
            <td class="text-left">
              {{ props.item.nombre }} {{ props.item.precio > 0 ? '[' + props.item.precio + moneda + ']' : '' }} ({{
                props.item.duracion
              }}
              min)
            </td>
            <td class="hidden-xs-only text-left">
              {{ props.item.descripcion }}
            </td>
            <td v-if="isReceptionist" class="hidden-xs-only text-left">
              {{ props.item.professionalName }}
            </td>
            <td class="text-right">
              <v-icon data-test="service-button-edit" small class="mr-2" @click="editItem(props.item)">mdi-pencil</v-icon>
              <v-icon data-test="service-button-delete" small @click="deleteItem(props.item)">mdi-delete</v-icon>
            </td>
          </tr>
        </template>

        <template slot="no-data"> <v-icon>mdi-video</v-icon> {{ $t('videoconsulta.sin_tiposvideo') }} </template>
      </v-data-table>
    </div>

    <!-- Modal Eliminar Servicio de Videoconsulta -->
    <span slot="widget-content">
      <v-dialog v-model="dialog_delete.dialog" dialog_delete max-width="500px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('tipovideo.confirm_delete_titulo') }}</span>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <p>{{ $t('tipovideo.confirm_delete_texto') }}</p>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey darken-1" text @click.native="dialog_delete.dialog = false">
              {{ $t('common.cancel') }}</v-btn
            >
            <v-btn
              color="red darken-1"
              text
              @click.native="
                dialog_delete.dialog = false;
                deleteService();
              "
              >{{ $t('common.confirm') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </span>
  </div>
</template>

<script>
import { tiposVideoconsulta, saveSettingServiceUrl, getHeader } from '@/config/config';

export default {
  name: 'Tablavideos',
  props: {
    videoconsultas: {
      type: Array,
      default: () => [],
    },
    presenciales: {
      type: Array,
      default: () => [],
    },
    tipoServicio: {
      type: Array,
      default: () => [],
    },
    tipo: {
      type: Number,
      default: 0,
    },
    paymentModule: {
      type: Boolean,
      default: false,
    },
    prevTextFeeMessage: {
      type: String,
      default: '',
    },
    isFreemium: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    isReceptionist: {
      type: Boolean,
      default: false,
    },
    taxTypes: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      mostrarDialog: false,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      headers: [
        { text: this.$t('common.status'), value: 'estado', sortable: false },
        { text: this.$t('common.name'), value: 'nombre' },
        { text: this.$t('common.detalles'), value: 'detalles', class: 'hidden-xs-only' },
        { text: this.$t('common.actions'), value: '', sortable: false, align: 'right' },
      ],
      estados: [
        { id: 0, name: this.$t('common.desactivado') },
        { id: 1, name: this.$t('common.activado') },
      ],
      items: this.videoconsultas,
      editedIndex: -1,
      editedItem: {
        id: 0,
        nombre: '',
        descripcion: '',
        precio: '',
        duracion: '',
        estado: 1,
        dias: [],
        tipo: 'videoconsulta',
        presencial: 0,
        taxTypes: 0,
        taxPercent: 0,
        importe: 0,
      },
      defaultItem: {
        id: 0,
        nombre: '',
        descripcion: '',
        precio: '',
        duracion: '',
        estado: 1,
        dias: [],
        tipo: 'videoconsulta',
        presencial: 0,
        taxTypes: 0,
        taxPercent: 0,
        importe: 0,
      },
      dialog_delete: {
        name: '',
        dialog: false,
      },
      dataPost: {
        user_id: JSON.parse(window.localStorage.getItem('auth_ehealth'))['id'],
        action: '',
        indice: 0,
        videoconsulta: {},
      },
      rules: {},
      moneda: '€',
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t('common.nuevo_servicio') : this.$t('videoconsulta.editar_servicio');
    },
  },
  created() {
    this.rules = {
      required: value => !!value || this.$t('common.obligatorio'),
      min: v => v > 0 || this.$tc('errors.mayor_cero', 0),
      min_or_equal: v => v >= 0 || this.$tc('errors.mayor_cero', 2),
    };
  },
  mounted() {
    if (this.isReceptionist) {
      this.headers.splice(3, 0, {
        text: this.$t('common.professional'),
        value: 'professional',
        class: 'hidden-xs-only',
      });
    }
  },

  methods: {
    cambiaPrecio(modo) {
      let precio = parseFloat(this.editedItem.precio);
      // TODO: FIXME!!
      if ((isNaN(precio) && modo !== 'writing') || precio < 0) {
        precio = 0;
      }

      if (this.isFreemium && precio <= 0) {
        precio = 1;
      }

      this.editedItem.precio = precio;
      this.editedItem.importe =
        Math.round((parseFloat(precio) + (parseFloat(precio) * this.editedItem.taxPercent) / 100) * 100) / 100;
    },

    cambiaDuracion() {
      if (
        this.editedItem.duracion === '' ||
        this.editedItem.duracion < 0 ||
        !this.editedItem.duracion.match(/^-?\d*(\.\d+)?$/)
      ) {
        this.editedItem.duracion = 0;
      }
    },

    modifyDuration() {
      const step = 5;
      this.editedItem.duracion = Math.ceil(this.editedItem.duracion / step) * step;
    },

    editItem(item) {
      this.editedIndex = this.videoconsultas.indexOf(item);
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem.id = item.id;
      this.editedItem.nombre = item.nombre;
      this.editedItem.descripcion = item.descripcion;
      this.editedItem.precio = this.paymentModule ? item.netAmount : 0;
      this.editedItem.duracion = item.duracion;
      this.editedItem.dias = item.dias;
      this.editedItem.estado = item.estado;
      this.editedItem.presencial = item.presencial;
      this.editedItem.taxType = item.taxType;
      this.editedItem.taxPercent = item.taxPercent;
      this.editedItem.importe = item.precio;
      this.editedItem.professionalName = item.professionalName;
      this.mostrarDialog = true;
    },

    deleteItem(item) {
      this.editedItem.id = item.id;
      this.editedIndex = this.videoconsultas.indexOf(item);
      this.dialog_delete.dialog = true;
    },

    deleteService() {
      this.$http
        .post(
          tiposVideoconsulta,
          { user_id: this.authUser.id, accion: 'eliminar', tipo_id: this.editedItem.id },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data.estado === '1') {
            this.videoconsultas.splice(this.editedIndex, 1);
            this.$emit('mostrarAlert', {
              tipo: 'success',
              icono: 'mdi-check',
              texto: this.$t('success.delete_common'),
            });
          } else {
            this.$emit('mostrarAlert', {
              tipo: 'error',
              icono: 'mdi-alert-circle',
              texto: this.$t('errors.try_again'),
            });
          }
        });
      this.close();
    },

    close() {
      // let param = { estado: true, tipo: 'tablavideos' };
      // this.$emit('cerrarDialog', param);
      this.mostrarDialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.servicios = [];
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      if (
        this.editedItem.nombre === '' ||
        (this.paymentModule && this.editedItem.precio === '') ||
        this.editedItem.duracion === ''
      ) {
        this.$emit('mostrarAlert', {
          tipo: 'warning',
          icono: 'mdi-alert-circle',
          texto: this.$t('errors.form_invalid'),
        });
      } else {
        this.editedItem.precio = this.paymentModule ? this.editedItem.precio : 0;
        this.dataPost.action = this.editedIndex > -1 ? 'editar' : 'nuevo';
        this.dataPost.indice = this.editedIndex;
        const modificaPresencial = this.editedItem.presencial !== this.videoconsultas[this.dataPost.indice].presencial;
        const postTipo = this.editedItem;
        this.$http
          .post(saveSettingServiceUrl, { user_id: this.authUser.id, tipo: postTipo }, { headers: getHeader() })
          .then(response => {
            if (response.status === 200 && response.data.estado === '1') {
              if (modificaPresencial) {
                this.$emit('editadoTipoVideo', {
                  tipo: this.tipo,
                  item: response.data.tipo,
                  indice: this.dataPost.indice,
                });
              } else {
                this.$emit('mostrarAlert', {
                  tipo: 'success',
                  icono: 'mdi-check',
                  texto: this.$t('success.save_common'),
                });
                Object.assign(this.videoconsultas[this.dataPost.indice], response.data.tipo);
                this.videoconsultas.push({});
                this.videoconsultas.pop();
              }
            } else {
              this.$emit('mostrarAlert', {
                tipo: 'error',
                icono: 'mdi-alert-circle',
                texto: this.$t('errors.try_again'),
              });
            }
            this.snackbar = true;
          })
          .catch(() => {
            this.$emit('mostrarAlert', {
              tipo: 'error',
              icono: 'mdi-alert-circle',
              texto: this.$t('errors.try_again'),
            });
            this.snackbar = true;
          });
        this.close();
      }
    },
  },
};
</script>

<style>
.titulo_diag {
  font-family: Roboto;
  font-weight: 300 !important;
  margin-bottom: -10px;
}
.tabla_videos {
  margin-top: -15px !important;
}
.availableDay {
  margin-top: -20px;
}
</style>
